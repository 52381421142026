@import '../../style/variables.scss';

$border-radius: 1rem;

.container {
  border-radius: $border-radius;

  .inner {
    display: flex;
    flex-direction: row;

    &.hideImage {
      .right {
        margin-left: unset;
      }
    }

    .delete {
      color: var(--vb-color-red);
      margin-top: 0.15rem;
      padding-left: 0.25rem;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .left {
      flex-basis: 150px;
      flex-shrink: 0;
      padding-left: 12px;

      &.withAvatar {
        flex-basis: unset;
      }

      &.noPadding {
        padding-left: 0;
      }
    }

    .right {
      flex-grow: 1;
      margin-left: 1rem;
      display: flex;
      flex-direction: column;

      .title {
        display: flex;
        flex-direction: column;
        padding: 0 12px;

        &.noPadding {
          padding: 0;
        }

        .meta {
          font-size: 0.86rem;
          font-style: italic;
          color: var(--vb-color-grey-mid);
          display: flex;
          justify-content: space-between;
          min-height: 1rem;

          .by {
            display: flex;
            gap: 0.6rem;
            padding: 0.4rem 0;
          }
        }
      }

      .content {
        .fullWidth {
          margin-top: 1rem;
          padding: 0 12px;

          &.noPadding {
            padding: 0;
          }
        }

        .description,
        .toggleContent,
        .bottom {
          padding: 0 12px;

          &.noPadding {
            padding: 0;
          }
        }

        .toggleContent {
          line-height: 1.5;
        }

        .bottom {
          margin-top: 0.3rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .actions {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-left: auto;
          }
        }
      }
    }
  }

  &.tablet {
    .inner {
      .left {
        flex-basis: 120px;

        &.withAvatar {
          flex-basis: unset;
        }
      }
    }
  }

  &.mobile {
    .inner {
      &.hideImage .right {
        margin-left: unset;
      }

      .left {
        flex-basis: 100px;

        &.withAvatar {
          flex-basis: unset;
        }
      }

      .right {
        &.withAvatar {
          margin-left: 1rem;
        }

        margin-left: 0.5rem;
      }
    }
  }
}
