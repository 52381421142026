.formFAQ {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .actions {
    align-self: center;
    display: flex;
    gap: 1rem;
  }
}
