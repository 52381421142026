@import '../../../../assets/style/variables.scss';

.item {
  margin: 0px 12px 4px 12px;
  padding: 8px 0px 12px 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid lightgrey;
  align-items: center;
  color: $vb-black;
  text-decoration: none;

  &:hover {
    color: $vb-blue;
    text-decoration: none;
  }

  &:last-child {
    border-bottom: 0;
  }

  &.condensed {
    padding: 5px;
  }

  .icon {
    margin: 0px 10px 0px 10px;
  }

  .name {
    flex-grow: 1;
    margin: 0px 10px 0px 10px;
    font-size: 15px;
    font-weight: 600;
  }

  .arrow {
    margin: 0px 10px 0px 10px;
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
  }
}
