.socialActions {
  display: flex;
  flex-direction: column;

  padding-left: calc(41px + 1rem);

  .buttons {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    align-self: flex-end;

    .icon {
      color: black;
      font-size: 1.25rem;
      left: unset;
    }

    .likeButton {
      display: flex;
      justify-content: center;
      align-items: center;

      transform: unset;
    }

    .pencil {
      height: 1.5rem;
    }

    .trash {
      height: 1.4rem;
    }
  }
}
