.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--vb-color-black);
  &:hover {
    cursor: pointer;
  }
  .innerText {
    text-align: center;
    font-size: 1.64rem;
    font-weight: 600;
    line-height: 2.2rem;
    .big {
      font-size: 2.86rem;
      line-height: 3.2rem;
    }
    .blue {
      color: var(--vb-color-blue);
    }
    .error {
      color: var(--vb-color-red);
    }
  }
  .imagePreview {
    width: 100%;
    position: relative;
    .topIconContainer {
      left: 12px;
      display: flex;
      position: absolute;
      top: -10px;
      & > * {
        margin-right: 0.4rem;
      }
    }
    .icon {
      width: 3rem;
      &.cancel {
        right: 12px;
        position: absolute;
        top: -10px;
      }
    }
    .file {
      width: 100%;

      &.loading {
        filter: contrast(0.2) brightness(1.7);
      }
    }
  }

  &.bordered {
    border: 2.5px dashed var(--vb-color-blue);
    min-height: 200px;
  }

  &.subdued {
    &.bordered {
      border: 1px solid var(--vb-color-grey-mid);
      min-height: 200px;
    }
    .innerText {
      color: var(--vb-color-grey-mid);
      font-weight: 400;
      font-size: 1rem;
      .big {
        font-size: 2.29rem;
        line-height: 2.29rem;
      }
    }
  }

  &.textarea {
    padding: 0 10px;
    border: 1.5px dashed var(--vb-color-blue);
    border-radius: 10px;

    .innerText {
      color: var(--vb-color-blue);
      font-weight: 400;
      font-size: 1.5rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.short {
  color: var(--vb-color-blue);
  text-decoration: none;
  display: block;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
  font-size: 1.14rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .text {
    margin-left: 1rem;
  }
}

/** Hide the file input */
.hiddenInput {
  display: none;
}

.uploadLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cropContainer {
  display: flex;
  flex-direction: column;

  .buttons {
    display: flex;
    & > * {
      margin-right: 1rem;
      flex-grow: 1;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
