@import '../../../style/variables.scss';

.single {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.43rem;

  .contentContainer {
    flex-grow: 1;
    margin-top: 0.14rem;
    display: flex;
    flex-direction: column;
    margin-left: 0.57rem;

    .name {
      font-size: 1.14rem;
      font-weight: 600;

      a {
        color: $vb-black;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .content {
      color: $mid-grey;
      font-size: 1rem;
      margin-bottom: 0.29rem;
    }

    .contentBottom {
      .timePosted {
        width: 2.86rem;
        display: inline-block;
        color: $mid-grey;
        font-size: 0.71rem;
        min-width: max-content;
      }
    }
  }

  .likesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 4.57rem;
    justify-content: flex-end;
    margin-top: 1.43rem;

    .likes {
      font-size: 0.86rem;
      flex-grow: 1;
      text-align: center;
    }

    .likeButton {
      width: 1.86rem;
      height: 1.86rem;
      cursor: pointer;
    }

    .spinnerWrapper {
      width: 1.86rem;
      height: 1.86rem;
      display: inline-block;
    }
  }

  .reportContainer {
    position: relative;
    display: inline-block;
    margin-right: 0.57rem;
    font-size: 0.86rem;
  }

  .likesContainer.userLiked {
    .likes {
      color: $vb-blue;
    }
  }

  .likesContainer.isUsers {
    .likeButton {
      cursor: auto;
    }
  }

  .meta {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .replies.hidden {
    display: none;
  }
}
