.author {
  display: inline-block;
  pointer-events: none;

  &.clickable {
    pointer-events: unset;
    cursor: pointer;
  }

  .avatar {
    margin: 0 0.3rem;
  }

  .dropdownWrapper {
    box-shadow: unset;
    
    .dropdown {
      min-width: 180px !important;
    }
  }
}
