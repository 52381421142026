.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 12px;

  .status {
    font-size: 15px;
    font-weight: 700;
  }

  .next {
    font-size: 15px;
    margin-right: 2rem;
    align-self: flex-end;

    &.mobile {
      margin-right: 3rem;
    }
  }

  .venue {
    margin-top: 1rem;
  }
}

.bottomButtons {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;

  .closestCities {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.confetti {
  position: fixed !important;
  inset: 0;
}
