@import '../../style/variables.scss';

.container {
  max-width: $max-width;

  &.sm {
    padding: 0;
  }

  &.padded {
    padding: 0 12px;
  }
}
