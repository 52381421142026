.selectorWrapper {
  margin-bottom: 1rem;
}

.nearMeWideContainer {
  margin: 0 1rem;
  margin-bottom: 2rem;
}

.bubbleFilter {
  margin-left: 5rem;
}

.mobileFilter {
  margin-bottom: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.bestTimeToGo {
  padding: 0 12px;

  .heading {
    font-size: 1.14rem;
    font-weight: 600;
  }

  .noItems {
    text-align: center;
  }
}
