@import '../../../assets/style/variables.scss';

.container {
  .imageContainer {
    position: relative;

    .image {
      width: 100%;
      height: auto;
      border-radius: 1rem;
    }

    .badge {
      position: absolute;
      height: 3rem;
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  .bottom {
    margin-top: 0.5rem;
    padding: 0 0.5rem;

    .nameContainer {
      .name {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 600;
        margin-bottom: 0;
      }
    }

    .date {
      font-style: italic;
      color: $dark-gray;
    }
  }

  .address {
    margin-top: 0.3rem;
    color: $dark-gray;
    font-size: 0.86rem;
    line-height: 1rem;
  }

  .distance {
    padding: 0 0.5rem;
  }

  .metaUpper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    .nameAndDate {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &.detailsPage {
    .imageContainer {
      .image {
        border-radius: 0;
        max-height: 50vh;
        object-fit: cover;
      }
    }
    .bottom {
      margin-top: 1rem;

      .nameContainer {
        .name {
          font-weight: 700;
        }
      }

      .address {
        margin-top: 1rem;
      }

      .description {
        margin-top: 0.5rem;
      }
    }

    &.mobile {
      .bottom {
        .nameContainer {
          .name {
            font-size: 1.65rem;
          }
        }
      }
    }
  }

  // &.tablet {
  // }

  &.mobile {
    .imageContainer {
      .image {
        border-radius: 0;
      }
    }
  }
}
