@import '../../../../style/variables.scss';

.replies {
  padding-left: 0.86rem;
  border-left: 1px solid $light-grey;

  .list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }

  .loadMoreContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;

    & > * {
      margin-bottom: 1.07rem;
    }

    .spinnerWrapper {
      width: 2.5rem;
      height: 2.5rem;
      display: inline-block;
    }
  }

  .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0.5rem 0;

    .input {
      flex-grow: 1;
      border-radius: 1.57rem;
      font-size: 0.86rem;
      padding-top: 0.57rem;
      padding-bottom: 0.57rem;

      textarea {
        width: 100%;
      }
    }

    .addReply {
      display: inline-block;
      width: 2.29rem;
      height: 2.29rem;
      margin-bottom: 0.36rem;
      margin-left: 0.57rem;
      cursor: pointer;
    }

    .spinnerWrapper {
      width: 2.29rem;
      height: 2.29rem;
      margin-bottom: 0.21rem;
      margin-left: 0.57rem;
    }
  }
}
