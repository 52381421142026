@import 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap';
@import '../../style/variables.scss';

.root {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;
  color: $vb-black;
  font-size: 14px;
  &.md {
    font-size: 13px;
  }
  &.sm {
    font-size: 12px;
  }
}
