.users {
  margin-right: 10px;
  display: inline-flex !important;
  vertical-align: middle;
  .innerAvatar {
    margin-right: -10px;
    outline: 0;
    -moz-outline-style: none;
    outline-style: none;
    border: 2px solid white;
  }
  .plus {
    margin-left: 13px;
    margin-right: -10px;
    align-self: center;
  }
}
