@import '../../style/variables.scss';

.radiusContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  width: 100%;

  > * {
    margin: 0 0.5rem;
    &.fullWidth {
      width: 100%;
    }
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    > * {
      margin: 0.25rem 0;
    }
  }

  &.scroll {
    display: grid;
    grid-auto-columns: max-content;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    overflow-x: auto;
    column-gap: 0.25rem;
    justify-items: center;
    align-items: center;
    padding-bottom: 1rem;
    padding-left: 0.1rem;
    justify-content: unset;

    .button {
      grid-row: 1;
      grid-column: auto;
    }
  }
}

.scrollWithLabel {
  display: grid;
  grid-template-columns: 15fr 85fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;

  .label {
    padding-bottom: 1rem;
    margin-right: 1rem;
  }
}
