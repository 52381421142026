.container {
  color: black;
}

.buttonContainer {
  & > * {
    justify-content: start;
    margin-top: 1rem;
  }
}

p {
  margin: 10px 0px;
}

.checkboxContainer {
  margin: 0px 0px 10px 10px;
  .checkbox {
    display: block;
  }
}
