.googleLoginBadge {
  display: flex;
  padding: 4px 7px 4px 4px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border: 1px solid #eaeaea;
  border-radius: 13px;
}
