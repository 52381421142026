@import '../../../style/variables.scss';

.check {
  position: relative;

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    margin-left: 0.4em;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 1.2em;
      height: 1.2em;
      left: 0;
      display: flex;
      align-items: center;
      border: 2px solid $vb-blue;
      border-radius: 50%;
    }
  }

  &.active {
    label::before {
      background: $vb-blue;
    }
  }
}
