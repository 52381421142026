.container {
  display: block;
}

.bottom {
  display: flex;
  justify-content: space-evenly;
}

.iosIcon {
  margin: 0 0.3rem;
  width: 1rem;
}
