@import '../../style/variables.scss';

$border-radius: 1rem;

.container {
  padding: 0.5rem;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  background-color: white;
  color: $vb-black;

  .image {
    border-radius: $border-radius;
    width: 10rem;
    height: 10rem;
    object-fit: cover;
  }

  .right {
    margin-left: 1rem;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    .name {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 600;

      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .description {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      align-items: flex-end;
      width: 100%;

      .pagination {
        display: flex;
        flex-direction: row;

        .next {
          margin-left: 1rem;
        }
      }
    }
  }

  &.tabletStyle {
    .right .name {
      font-size: 1.3rem;
    }
  }

  &.mobileStyle {
    .right .name {
      font-size: 1.1rem;
    }
  }
}
