.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;

  &.mobile {
    gap: 0;
  }

  .title {
    font-size: 1.15rem;
    padding: 0 12px;
  }

  .articles {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    list-style: none;
    padding-left: unset;
  }
}
