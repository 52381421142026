.rateVideoMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;

  .title {
    font-size: 1.15rem;
  }

  .actions {
    display: flex;
    gap: 30px;

    .great > div {
      max-width: unset;

      .iconsWrapper {
        display: flex;
      }
    }

    .icon {
      width: 26px;
      height: auto;

      &.active {
        fill: var(--vb-color-blue);
        stroke: var(--vb-color-blue);
      }
    }
  }
}
