.carousel {
  display: flex;
  flex-direction: column;
  position: relative;

  height: 100svh;
  width: 100vw;

  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;

  position: relative;
  padding-bottom: 2rem;

  &.mobile {
    padding-bottom: unset;
  }

  .noItems {
    display: flex;
    justify-content: center;
    padding: 1.5rem 1rem 1rem;
    font-size: 1.14rem;
  }

  &.disabled {
    overflow-y: hidden;
  }
}
