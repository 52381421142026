@import '../../style/variables.scss';

.container {
  display: flex;
  flex-direction: row;

  .left {
    min-width: max-content;

    .avatarSkeleton {
      display: block;
      line-height: 1;
      height: 100%;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding-left: 0.4rem;
  }

  &.sm {
    .name {
      font-size: 1rem;
    }

    .subtitle {
      font-size: 0.79rem;
      font-weight: 400;
    }

    .avatar {
      margin-right: 0.8rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &.lg {
    .name {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .subtitle {
      font-size: 1.14rem;
      line-height: 1.4rem;
      font-weight: 400;
    }

    .avatar {
      margin-right: 0.8rem;
      width: 4.29rem;
      height: 4.29rem;
    }
  }
}
