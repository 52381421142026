.container {
  margin-top: 1rem;
  margin-bottom: 2rem;

  .myExperience {
    font-weight: 600;
    font-size: 16px;
    color: var(--vb-color-black);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;

    .emojiContainer {
      margin: 0.5rem;
      border-radius: 27px;

      .emojiOnClick {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .emoji {
          width: 35px;
          height: 35px;
          min-width: 35px;
          min-height: 35px;
          display: inline-block;
          cursor: pointer;
        }
      }
    }

    .activeEmoji {
      box-shadow: 0px 0px 15px var(--vb-color-blue);
      &.backgroundOnSelect {
        background-color: var(--vb-color-blue-highlight);
      }
      &::after {
        content: '';
        background: var(--vb-color-blue);
        position: absolute;
        width: 35px;
        margin-top: 2px;
        height: 2px;
      }
    }
  }

  .reviewForm {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .left {
      flex-grow: 1;

      .content {
        width: 88%;
        margin-bottom: 12px;
      }

      .includeBy {
        margin-top: 1rem;
        margin-bottom: 1.2rem;

        .checkbox {
          margin-right: 0.8rem;
        }
      }
    }

    .right {
      width: 395px;
      height: 258px;
      padding-bottom: 1.5rem;
    }

    .submitContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &.lteMdBreakpoint {
    .reviewForm {
      flex-direction: column;

      .left {
        .content {
          width: 100%;
          margin-bottom: 0;
        }
      }

      .right {
        width: 100%;
        height: auto;
      }
    }
  }

  .emojiSelector {
    margin-left: 0.7rem;
  }
}
