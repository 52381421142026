.askQuestion {
  padding: 0 12px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;

  .input {
    background-color: #fff;
    cursor: text;
  }

  :global(.form-control) {
    padding: 0.375rem 1rem;
  }
}
