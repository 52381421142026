@import '../../style/variables.scss';

.container {
  color: $vb-black;
  .linkContainer {
    position: relative;
    .shareLink {
      width: 100%;
      padding: 12px;
      height: 42px;
      border: 1px solid #cbcbd6;
      box-shadow: none;
      padding-right: 40px;
    }
    .iconRight {
      position: absolute;
      top: 0;
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: flex-end;
      padding-right: 15px;
      .copyIcon {
        z-index: 4;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  /** "Copy" tooltip */
  .showTooltip::before {
    z-index: 4;
    position: absolute;
    background-color: $vb-black;
    border-radius: 5px;
    color: #fff;
    content: attr(data-tooltip);
    padding: 0.4rem;
    left: 50%;
    margin-left: -26px;
    margin-top: -20px;
    text-transform: none;
  }
  .showTooltip::before,
  .showTooltip::after {
    z-index: 4;
    color: #efefef;
    font-size: 1rem;
    opacity: 0;
    pointer-events: none;
    text-align: center;
  }
  .showTooltip::before,
  .showTooltip::after {
    z-index: 4;
    opacity: 1;
  }
  /** End of "Copy" tooltip */
}

.inputSpinner {
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
}

.shareButtons {
  display: flex;
}
