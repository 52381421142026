.mobileCardContainer {
  width: 100%;
  margin-bottom: 24px;
}

.desktopCardContainer {
  flex: 0 0 auto;
  width: 98%;
  margin-left: 1%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
