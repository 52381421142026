@import '../../style/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  overflow-y: inherit;
  &.scroll {
    overflow-y: auto;
  }
  padding: 0 12px;

  .list {
    display: flex;
    flex-direction: column;
  }
}
