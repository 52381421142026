.container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
  max-width: 100vw;
  overflow: hidden;
  flex-wrap: wrap;

  & > * {
    margin-top: 0.5rem;
  }
}
