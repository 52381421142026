.description {
  font-size: 1rem;
  flex-basis: 100%;
  line-height: 1.5;

  &.truncated {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: var(--numberOfLines);
    -webkit-box-orient: vertical;
  }
}

.descriptionText {
  color: var(--vb-color-black);
  text-decoration: none;
}

.category {
  font-size: 0.86rem;
  color: #aaabbb;
}

.articles {
  margin-top: 0.5rem;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  line-height: 1.85;
}
