@import '../../../style/variables.scss';
@import '../../../style/util.scss';

.header {
  padding: 7px 12px;
  width: 100%;
  top: 0;
  left: 0;
  transition: transform;

  &.blurBackground {
    @include background-blur(5px);
  }

  &.noParentPosition {
    z-index: 1000;
    position: sticky;
    &.fixed {
      position: fixed;
    }
  }

  .inner {
    display: flex;
    flex-direction: row;
  }

  .left {
    padding: 12px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    .tabs.white > * {
      color: white;
    }
    .tabs > * {
      padding: 0px 10px;
      margin: 0px 10px;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        color: $vb-blue;
      }
    }
    .tabs .fakeTab {
      color: $vb-black;
      &:hover,
      &:active {
        color: $vb-blue;
      }
    }
  }
}

// Prevent the header from hanging off the homepage when the popup is open. We have to handle the homepage case
// specially because the positioning on the homepage is fixed instead of sticky.
:global(.modal-open) {
  .header.fixed {
    width: CALC(100% - 15px);
  }
  .header.fixed.mobile {
    width: 100%;
  }
}

.profileDropdown * {
  color: $vb-black;
  &:hover {
    text-decoration: none;
    color: $vb-black;
  }
}

.spinnerContainer {
  display: flex;
  align-items: center;
}
