@import '../../style/variables.scss';

.noUnderline {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &::after {
    // Remove the blue underline for selected items
    background-color: transparent;
  }
}

.onClick {
  color: $vb-black;
}

.createPost {
  display: flex;
  align-items: center;
  .createPostIcon {
    width: 17px;
    height: 17px;
    margin-right: 3px;
  }
}

.bubbleLink {
  display: block;
}

.noUnderline {
  &:hover {
    text-decoration: none;
  }
  &::after {
    // Remove the blue underline for selected items
    background-color: transparent;
  }
}

.unreadActivity {
  &:after {
    content: '';
    background-color: $vb-red;
    width: 6px;
    height: 6px;
    display: inline-block;
    margin-left: 4px;
    border-radius: 50%;
    position: relative;
    top: -8px;
  }
}
