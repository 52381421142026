.filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  gap: 0.5rem;
  padding-left: 6px;
  list-style: none;

  .option {
    cursor: pointer;
    color: VAR(--vb-color-grey-dark);

    &.active {
      color: VAR(--vb-color-blue);
      pointer-events: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
