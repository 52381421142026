.auth {
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: #0073aa;
      transition: color 110ms ease-in-out;
    }
  }

  h1 {
    font-size: 4.5rem;
    margin: 20px auto 20px auto;
  }
  &.lteLgBreakpoint h1 {
    margin: 10px auto 10px auto;
  }

  input {
    margin-bottom: 26px;
  }

  button[type='submit'] {
    width: 100%;
    margin: 10px 0px;
  }
}
