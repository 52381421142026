@import '../../style/variables.scss';

.spinner {
  color: $vb-blue;
}

.center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.page {
  flex-grow: 1;
  padding: 1rem;
}
