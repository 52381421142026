.container {
  padding: 0.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.bottom {
  display: flex;
  justify-content: space-evenly;
  & > * {
    display: inline;
  }
}

.cardOverride {
  padding-right: 0 !important;
  padding-bottom: 0.5rem !important;
}
