.single {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.43rem;

  .avatar {
    min-width: 2.71rem;
    max-width: 2.71rem;
    border-radius: 50%;
  }

  .contentContainer {
    flex-grow: 1;
    margin-top: 0.14rem;
    display: flex;
    flex-direction: column;
    margin-left: 0.57rem;

    .name {
      font-size: 1.07rem;
      font-weight: 600;

      a {
        color: var(--vb-color-black);
        &:hover {
          text-decoration: none;
        }
      }
    }

    .content {
      color: var(--vb-color-grey-mid);
      font-size: 0.93rem;
      margin-bottom: 0.29rem;
    }

    .contentBottom {
      display: flex;
      align-items: center;

      .timePosted {
        width: 2.86rem;
        display: inline-block;
        color: var(--vb-color-grey-mid);
        font-size: 0.64rem;
        min-width: max-content;
      }

      .reportContainer {
        position: relative;
        display: inline-block;
        margin-right: 0.57rem;
        font-size: 0.86rem;
      }
    }
  }
}
