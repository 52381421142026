/**
 * General styles. Style dependencies are imported here.
 */

// This is preloaded, now.
// @import 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap';

@import 'react-redux-toastr/src/styles/index';
@import 'bootstrap/dist/css/bootstrap.min.css';

// TODO: Fix the problem and remove this:

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
