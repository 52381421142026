.bubble {
  margin: 0.5rem 0.75rem;
  padding: 0.25rem 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--vb-color-white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  // Styles for text/icon when not hovering
  .iconWhite {
    display: none;
  }
  .iconBlack {
    height: 1.75rem;
    margin-left: 1rem;
  }
  p {
    white-space: nowrap;
  }

  // Styles for when hovering
  &:hover {
    background: var(--vb-color-blue);
    cursor: pointer;
    p {
      color: var(--vb-color-white);
    }
    .iconWhite {
      display: block;
      height: 1.75rem;
      margin-left: 1rem;
    }
    .iconBlack {
      display: none;
    }
  }
}
