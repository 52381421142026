.container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.clickCapture {
  position: absolute;
  width: 100%;
  height: calc(100% - 64px);
  top: 0;
  left: 0;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.floatingButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.7;

  .inner {
    border: 2px solid white;
  }
}

.fastForward {
  right: 0.7rem;
}

.toggleMute {
  left: 0.7rem;
}

.unmute {
  position: absolute;
  bottom: 8px;
  left: 67px;
  pointer-events: none;

  &.isMobile {
    bottom: 14px;
    left: 73px;
  }
}

.loaderContainer {
  display: block;
  line-height: 1;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
