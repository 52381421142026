@import '../../../assets/style/variables.scss';
/* search/autofill styles */

.searchWrapper {
  min-width: 150px;
  width: 100%;
  font-size: 16px;
  align-items: center;
  display: flex;
  position: relative;
  z-index: 1;
  .searchInput {
    -webkit-appearance: none;
    z-index: 1;
    position: relative;
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px;
    padding-left: 16px;
    padding-right: 85px;
    border: none;
    text-decoration: none;
    line-height: normal;
    font-size: 16px;
    flex: 3 0 25px;
    border-radius: 25px;

    &.searchInputBordered {
      border: 1px solid #aaabbb !important;
    }
  }
  .iconsRight {
    z-index: 3;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    pointer-events: none;
    &.separate {
      margin-top: 0px;
      width: fit-content;
      margin-left: 16px;
    }
    .spinnerWrapper {
      width: 55px;
      padding-right: 15px;
    }
    .spinnerWrapperInner {
      width: 40px;
      padding-right: 15px;
    }
  }
  // Target all icons in the search bar
  .iconsRight > * {
    cursor: pointer;
    pointer-events: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 1rem;
  }

  .extraPadding > *:last-child {
    padding-right: 1.5rem;
  }

  :global(.dropdown) {
    z-index: 0;
    top: -8px;
  }

  &.condensed {
    .searchInput {
      font-size: 1rem;
    }
  }
}

.searchBar {
  // min-width: 220px;
  flex-grow: 1;
}

.autofillDropdown {
  z-index: -1 !important;
}
