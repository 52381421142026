.filter {
  display: none;
  flex-direction: column;

  height: 400px;
  width: 320px;

  background-color: white;
  border-radius: 24px;
  box-shadow: rgba(50, 50, 50, 0.2) 0px 0px 8px;

  position: fixed;
  transform: translate(-50%, -50%);
  top: 65%;
  left: 50%;

  &.shown {
    display: flex;
  }

  &.fullPage {
    box-shadow: unset;
    height: 100%;
    width: 100%;
    min-height: 0;
    position: static;
    transform: unset;

    .body {
      padding-top: 3rem;
    }

    .actions {
      padding-bottom: 1.5rem;
    }
  }

  .header {
    padding: 1rem 1rem 0;

    .searchBar input {
      border-radius: 18px !important;
    }
  }

  .body {
    display: flex;
    flex-direction: column;

    height: 100%;
    padding: 2rem 1rem 1rem;
    overflow: auto;

    .filters {
      margin-top: 1.5rem;
      justify-content: space-between;
      font-size: 12px !important;
    }

    .switchContainer {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-items: center;
      gap: 1rem;

      align-self: center;
      color: VAR(--vb-color-grey-dark);
    }
  }

  .actions {
    margin-top: auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;

    .save {
      width: 55%;
      border-radius: 10px;
    }
  }
}
