.header {
  font-weight: 600;
  line-height: inherit;
  .title {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  .name {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  .link {
    color: var(--vb-color-blue);
  }
}
