@import '../../../../assets/style/variables.scss';

.comments {
  font-size: 0.9em;
  color: $vb-blue;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.postMeta a.details {
  font-size: 1rem;
  color: $vb-black;
  outline: none;
  text-decoration: none;
  margin-top: 3px;
  img {
    border-radius: 50%;
    width: 41px;
    height: 41px;
  }
  p {
    margin-left: 10px;

    &.venueTitle {
      margin-left: 2px;
    }
  }
}
.ago {
  font-size: 10px;
  color: $mid-gray;
}
.caption {
  margin: 2px;
  font-size: 0.95em;
  line-height: 20px;
  font-weight: 400;
  white-space-collapse: preserve-breaks;
}

.topComment {
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;
  align-items: center;
  width: 50%;

  .topCommentRight {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    .topCommentName {
      font-size: 0.9rem;
      margin: 0;
      color: var(--vb-color-black);
      line-height: 1.5rem;
    }
    .topCommentContent {
      font-size: 0.8rem;
      margin: 0;
      color: var(--vb-color-grey-dark);

      overflow: hidden;
      line-height: 1rem;
      text-overflow: ellipsis;
      display: -webkit-box;
      max-height: 2rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
