/* -------------------------------------------------------------------------- */
/*                 Size (identical heights to button sizes)                   */
/* -------------------------------------------------------------------------- */
.tny {
  height: 1.21rem;
}

.xs {
  height: 1.6rem;
}

.sm {
  height: 2.5rem;
}

.med {
  height: 2.86rem;
}

.lg {
  height: 3.57rem;
}

.bubble {
  border-radius: 100%;
}
