.buttonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .group {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
