@import '../../../../assets/style/variables.scss';

.wrapper {
  padding-right: 0 !important;

  .explore {
    z-index: 2;
    cursor: pointer;
    color: white;
    background: $vb-red;
    display: flex;
    align-items: center;
    font-size: 1rem;
    pointer-events: auto;
  }
}
