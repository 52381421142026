@import '../../style/variables.scss';

.container {
  position: absolute;
  bottom: 8px;
  right: 12px;
  font-size: 1rem;
  color: $dark-gray;
  user-select: none;
  pointer-events: none;

  &.over {
    color: $vb-red;
  }
}
