.sectionSelector {
  position: fixed;
  z-index: 2;
  width: 100vw;
  left: 0px;

  transition: transform;
  pointer-events: none;
  .component {
    pointer-events: all;
  }

  &.center {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
