.input {
  width: 100%;
}

.emojiSelector {
  margin-bottom: 1rem;
}

.feedback {
  margin-top: 1rem;
}

.buttons {
  display: flex;
  margin-top: 1.5rem;

  .button {
    margin-left: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
  }
}
