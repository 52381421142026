@import '../../../style/variables.scss';

.header {
  font-size: 1.3rem;
  text-align: center;
  margin: 1.75rem 0 1.75rem;
  font-weight: 600;

  &.mobile {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.8rem;
  }

  &.padded {
    padding: 0 50px;
  }

  .trigger {
    cursor: pointer;
    user-select: none;
    color: $vb-blue;
    &:hover {
      color: $vb-blue-highlight;
    }
  }

  .hiddenGems {
    text-decoration: none;
  }
}

.ellipsis {
  position: absolute;
  top: 50%;
  right: 0.8rem;
  transform: translateY(-50%);
  z-index: 1000;
}
