.form label {
  color: var(--vb-color-grey-mid);
  font-size: 1.07rem;
  margin-bottom: 20px;
}

.container {
  flex: 1 0 480px;
}

.spinnerContainer {
  height: calc(2.86rem + 20px);
}
