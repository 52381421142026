@import '../../style/variables.scss';

a {
  font-size: inherit;
  color: $vb-blue;
}

a.reset {
  color: inherit;
  text-decoration: inherit;
  &:hover {
    text-decoration: inherit;
    color: inherit;
  }
}
a.noHoverStyle {
  &:hover {
    text-decoration: inherit;
    color: $vb-blue;
  }
}
