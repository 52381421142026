.postTitle {
  padding: 0 12px;
  margin-top: 1rem;
}

.title {
  font-size: 1.14rem;
  font-weight: 600;
  line-height: 1.5;
}

.meta {
  font-size: 0.86rem;
  font-style: italic;
  color: var(--vb-color-grey-mid);
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 1rem;
  padding: 0 12px;

  .sectionEditor {
    display: flex;
    gap: 0.6rem;
  }
}

.articles {
  padding-left: unset;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  list-style: none;
}
