.itineraryDay {
  padding: 0 12px;
  line-height: 1.5;

  .imageRow {
    display: flex;
    margin: 1rem 0;
    padding-left: 2rem;
    gap: 1rem;

    img {
      width: 33%;
      aspect-ratio: 1.61803 / 1;
      object-fit: cover;
      flex-shrink: 1;
      min-width: 0;
    }
  }

  .venueImage {
    width: 100%;
    min-width: 0;
    aspect-ratio: 1.61803 / 1;
    object-fit: cover;
    margin: 1rem 0;
  }

  ul {
    margin-bottom: unset;
  }

  .video {
    margin: 1rem 0;
    position: relative;
  }
}
