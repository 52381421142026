.sharePopup {
  display: flex;
  flex-direction: column;
  max-height: 100%;

  color: var(--vb-color-black);
  .linkContainer {
    .shareLink {
      width: 100%;
      margin-bottom: 8px;
      padding-right: 40px;
      padding: 12px;
      height: 42px;
      border: 1px solid #cbcbd6;
      box-shadow: none;
      padding-right: 40px;
    }
  }

  /** "Copy" tooltip */
  .showTooltip::before {
    position: absolute;
    background-color: var(--vb-color-black);
    border-radius: 5px;
    color: #fff;
    content: attr(data-tooltip);
    padding: 0.4rem;
    left: 50%;
    margin-left: -26px;
    margin-top: -20px;
    text-transform: none;
  }
  .showTooltip::before,
  .showTooltip::after {
    color: #efefef;
    font-size: 1rem;
    opacity: 0;
    pointer-events: none;
    text-align: center;
  }
  .showTooltip::before,
  .showTooltip::after {
    opacity: 1;
  }
  /** End of "Copy" tooltip */

  .iconRight {
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: flex-end;
    margin-top: -40px;
    z-index: 1;
    .copyIcon {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .shareButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    .logoContainer {
      background: var(--vb-color-blue);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      .logo {
        width: 32px;
        height: 32px;
      }
    }
  }
}
