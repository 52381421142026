@import '../../style/variables.scss';

.iconButton {
  min-width: 25px;
  max-width: 25px;
  font-size: 10px;
  justify-content: center;
  img {
    width: 100%;
  }
  .subtitle {
    font-weight: 500;
    color: #aaabbb;
    font-size: 0.9rem;
    line-height: 14px;
    width: 100%;
    overflow: visible;
    display: flex;
    justify-content: center;
    text-align: center;
    white-space: nowrap;

    &.stacked {
      white-space: normal;
    }
  }
  &:hover {
    cursor: pointer;
  }
  &.filled {
    color: #ffffff;
    background-color: $vb-blue;
    border-radius: 50%;
    img {
      height: 60%;
      width: auto;
    }
  }
  &.textRight {
    gap: 0.5rem;
  }
}
