@import '../../style/variables.scss';

.container {
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  > * {
    margin: 0.25rem 0;
    &.fullWidth {
      width: 100%;
    }
  }
}
