.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .image {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  .ytButton {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 4rem;
  }
}
