.ellipsisContainer {
  pointer-events: none;
  flex-shrink: 0;
  background-color: white;

  :global(.dropdown) {
    display: inline-block;
    pointer-events: all;
  }
  .ellipsis {
    padding: 1px 6px;
  }
}
