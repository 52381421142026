.container {
  display: flex;
  flex-direction: row;
  &.lg {
    padding: 1rem 0;
  }
  &.sm {
    padding: 0.86rem 0;
  }

  .left {
    flex-grow: 1;
  }

  border-bottom: 0.5px solid rgba(0, 0, 0, 0.17);
  &:last-child {
    border-bottom: none;
  }

  .actionArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
