.dropdown {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: initial;
  &.padded {
    padding-left: 1rem;
    padding-right: 1rem;

    .inputContainer {
      padding: 0;
    }
  }
  min-width: max-content;
}

.inputContainer {
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.outdoor {
  width: 100%;
  margin-top: 0.5rem;
}
