@import '../../style/variables.scss';

.link {
  color: $vb-blue;
  &.noStyle {
    color: inherit;
    text-decoration: inherit;
    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }
}
