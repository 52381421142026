@import '../../style/variables.scss';

.item {
  appearance: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0px 12px;
  display: block;
  text-decoration: none;
  min-width: 80px;
  color: $vb-blue;
  line-height: 1.9rem;
  text-align: center;
  min-width: max-content;
  a {
    text-decoration: none;
  }
  &:hover {
    background-color: $light-gray;
    color: $vb-blue-highlight;
    a {
      color: $vb-blue-highlight;
      text-decoration: underline;
    }
  }
  &.big {
    font-size: 1.2rem;
    line-height: 2rem;
    color: $vb-black;
    &:hover {
      color: $vb-blue;
    }
  }
  &.red {
    color: $vb-red;
  }
}
