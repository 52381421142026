@import '../../style/variables.scss';

.label {
  font-size: 0.86rem;
  margin-left: 6px;
  color: $dark-gray;

  &.noMargin {
    margin: 0;
  }
}

.required {
  margin-left: 0.5rem;
  font-size: 0.79rem;
}
