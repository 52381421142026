@import '../../style/variables.scss';

.tab {
  color: $vb-black;
  text-decoration: none !important;
  padding: 0px 8px;
  margin: 0px 8px 5px 8px;
  white-space: nowrap;

  &:hover {
    color: $vb-blue;
  }

  position: relative;
  &.active,
  &:active {
    color: $vb-blue;

    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      background-color: $vb-blue;
      height: 4px;
      border-radius: 4px 4px 0px 0px;
      position: absolute;
      left: 0;
      bottom: -6px;
    }
  }

  &.linkStyle {
    &.active,
    &:active {
      &::before {
        content: none;
      }
    }
  }
}
