@import '../../../style/variables.scss';

.description {
  line-height: 1.5rem;
  
  &.pointer {
    cursor: pointer;
  }

  &.truncated {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: var(--numberOfLines);
    -webkit-box-orient: vertical;
  }

  &.hover {
    &:hover {
      color: VAR(--vb-color-blue);
    }
  }

  .margin {
    margin-right: 0.75rem;
  }

  .author {
    color: var(--vb-color-grey-mid);
    font-style: italic;
    font-size: 0.86rem;
  }
}
