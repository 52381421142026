.testimonial {
  border: 2px solid var(--vb-color-grey-light);
  border-radius: 5px;
  padding: 15px;
  display: grid;
  grid-template-rows: 4fr 0.8fr;

  &.mobile {
    // The space around the edge of the testimonial is a bit much
    // when the testimonials are in a single column.
    // This reduces that space a bit.
    width: 120%;
    margin-left: -10%;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .image {
      max-width: 25%;
      border-radius: 50%;
      height: auto;

      &.mobile {
        max-width: 33%;
      }
    }

    .description {
      margin-left: 20px;
      font-size: 1.5rem;
      &.mobile {
        font-size: 1.15rem;
      }
    }

    .stars {
      width: 25%;
      font-size: 150%;
      margin-right: 5%;
      text-align: center;
      color: var(--vb-color-red);
      margin-bottom: 0;
      &.mobile {
        margin-top: 0;
        width: 33%;
      }
    }

    .name {
      font-size: 1.2rem;
      margin-bottom: 0;
      &.mobile {
        font-size: 1.15rem;
        margin-top: 0;
      }
    }
  }
}
