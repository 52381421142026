.avatar {
  width: 41px;
  height: 41px;
  border-radius: 50%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;

  &.noBorderRadius {
    border-radius: 0;
  }
}

.pointer {
  cursor: pointer;
}

.loaderContainer {
  position: relative;

  &.fullWidth {
    height: 0;
    width: 100%;
  }

  .loader {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: block;
    line-height: 1;
  }
}
