@import '../../style/variables.scss';

.container {
  color: $vb-black;
  max-height: 70vh;
  padding: 0 0.57rem 0.57rem 0.57rem;
  display: flex;
  flex-direction: column;

  .list {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .inner {
      margin-right: 0.86rem;

      .spinner.withMargin {
        margin-bottom: 1rem;
      }
    }
  }

  .loadMoreContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 1.43rem;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;

    .textArea {
      display: flex;
      flex-direction: row;
      align-items: center;

      > div {
        max-width: 320px;
        width: 100%;

        flex-grow: 1;

        textarea {
          width: 100%;
        }
      }

      .input {
        width: 100%;
        min-height: 0;
        border-radius: 1.57rem;
        font-size: 1rem;
        padding-top: 0.71rem;
        padding-bottom: 0.71rem;
      }

      .addComment {
        display: inline-block;
        width: 2.29rem;
        height: 2.29rem;
        margin-left: 0.7rem;
        cursor: pointer;
      }

      .spinnerWrapper {
        width: 2.29rem;
        height: 2.29rem;
        margin-bottom: 0.21rem;
        margin-left: 0.57rem;
      }
    }

    .author {
      display: flex;
      gap: 0.5rem;
    }
  }
}
