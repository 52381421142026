.container {
  text-align: center;
  font-size: 1rem;
  font-style: italic;

  &.noCenter {
    text-align: inherit;
  }

  .button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }
}
