.itinerary {
  padding: 0 12px;

  .heading {
    font-size: 1.14rem;
    font-weight: 600;
  }

  .days {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-left: 0;
  }
  
}