.about {
  margin-bottom: 1rem;
}

.imageDim {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;

  strong {
    margin-right: 0.5rem;
  }
}

.bottom {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
