.city {
  &.desktop {
    margin-bottom: 4rem;
    max-width: 50%;
  }
  .carouselImageContainer {
    height: 400px;
    &.mobile {
      height: 250px;
    }
    .carouselImage {
      object-fit: cover;
      height: 100%;
      overflow: hidden;
      &.desktop {
        border-radius: 13px;
      }
      &.mobile {
        height: 250px;
      }
    }
  }
}
