@import '../../../style/variables.scss';

.input {
  height: 40px;
  border-radius: 20px;
  box-shadow: rgb(50 50 50 / 20%) 0px 0px 8px;
  resize: none;
  border: 0px;
  color: var(--vb-color-black);
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;

  &:focus,
  &:focus-visible {
    box-shadow: $vb-blue 0px 0px 8px;
  }
  &.hasButton {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

.label {
  // color: var(--vb-color-grey-dark);
  display: block;
}
