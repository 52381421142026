.container {
  display: flex;
  justify-content: space-between;
}

.title {
  display: block;
  width: 25rem;
  margin-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.right {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  &.mobile {
    flex-direction: column;
  }
}

.line {
  width: 50px;
  margin: 5px 0;
  border-top: solid 1px var(--vb-color-blue);
}
