.commentsHeader {
  font-size: 2.2rem;
  font-weight: 600;

  .backButton {
    margin-left: 0.43rem;
    margin-right: 0.86rem;
    width: 2.2rem;
    cursor: pointer;
  }

  .backButton ~ * {
    vertical-align: middle;
  }
}
