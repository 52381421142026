.container {
  .content {
    font-size: 1.14rem;
  }
  .buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
  }
}
