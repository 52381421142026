.container {
  display: flex;
  flex-direction: column;

  min-height: 350px;
  max-height: 500px;

  .heading {
    margin-bottom: 2rem;
  }

  .spinner {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 1.5rem;
  }

  .noQuestions {
    flex-grow: 1;
    text-align: center;
  }
}
