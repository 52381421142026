@import '../../style/variables.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  .input {
    height: 100px;
    min-height: 100px;
    border-radius: inherit;
    overflow: auto;
    resize: none;
    color: $vb-black;
    font-family: Open Sans, sans-serif;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    padding: 12px;
    border: none;
    box-shadow: none;

    &.grow {
      min-height: unset;
    }

    &.asText {
      color: inherit;
      font-size: inherit;
      padding: 0;
      font-family: inherit;
      font-weight: inherit;
      border-radius: 0;
      line-height: normal;
    }

    &:focus,
    &:focus-visible {
      box-shadow: none;
      outline: none;
    }
  }

  textarea {
    -webkit-appearance: none;
  }

  .photoUpload {
    margin: 1rem;
    width: 200px;
    height: 50px;
    min-height: 50px !important;
  }

  &.borderShadow {
    box-shadow: rgb(50 50 50 / 20%) 0px 0px 8px;
    border: 0px;
  }

  &.borderGrey {
    border: 1px solid $mid-grey;
  }

  &.borderBlue {
    border: 1px solid $vb-blue;
  }

  &:focus-within,
  &:focus-visible {
    box-shadow: $vb-blue 0px 0px 8px;
    outline: none;
  }
}
