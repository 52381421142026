.badgeLabel {
  color: var(--vb-color-blue);
  font-size: 0.9rem;
  font-weight: normal;
  margin-left: 0.5rem;
  padding: 0.2rem 0.5rem;
  line-height: 1rem;
  border: 1px solid var(--vb-color-blue);
  border-radius: 0.4rem;
}
