.container {
  height: 94%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  scroll-snap-align: start;

  &.mobile {
    height: 100%;
    padding-top: unset;

    .carouselCard {
      aspect-ratio: unset;
      width: 100%;

      .image,
      .meta {
        border-radius: unset;
      }

      .actions {
        right: 15px;
      }
    }
  }

  .carouselCard {
    margin-left: auto;
    margin-right: auto;

    aspect-ratio: 9/16;
    border-radius: 12px;
    min-height: 0;

    position: relative;

    .image {
      border-radius: 12px;
      aspect-ratio: inherit;

      img {
        height: 100svh !important;
        width: 100% !important;
        aspect-ratio: inherit;
        object-fit: cover;
      }
    }

    .venueDetails {
      display: flex;
      flex-direction: column;
      width: 75%;

      position: absolute;
      top: 25px;
      right: 15px;

      color: white;
      font-weight: 700;
      text-align: right;
      text-decoration: underline;
      cursor: pointer;
    }

    .audioControl {
      position: absolute;
      top: 35px;
      left: 25px;
      cursor: pointer;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
    }

    .actions {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      position: absolute;
      bottom: 27%;
      right: -45px;
    }

    .meta {
      display: flex;
      flex-direction: column;
      color: white;
      align-items: flex-start;

      position: absolute;
      bottom: 0;
      left: 0;

      border-radius: 0 0 12px 12px;
      background-color: rgba(90, 90, 90, 0.8);
      padding: 8px 12px;
      width: 100%;

      .bubbles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        width: 100%;

        position: absolute;
        padding: 12px;
        left: 0;
        bottom: 100%;

        .row {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .bubble {
          background-color: VAR(--vb-color-grey-mid);
          border: 2px solid white;
          padding: 0.4rem 0.8rem;
          border-radius: 0.5rem;
          max-width: 65%;
          line-height: 1.5;
        }
      }

      .name {
        font-size: 1.14rem;
        font-weight: 700;
      }

      .locationName {
        font-weight: 700;
      }

      .fact {
        font-weight: 500;
        margin-top: 0.3rem;
        overflow: hidden;
        line-height: 1.5;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  .zuzuLink {
    position: absolute;
    top: 25px;
    left: 25px;

    font-size: 1.14rem;
    color: white;
    cursor: pointer;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}
