@import '../../../assets/style/variables.scss';

.footer {
  display: flex;
  height: $mobile-footer-height;
  padding: 0 7%;
  background-color: white;
  box-shadow: rgba(50, 50, 50, 0.2) 0px 0px 8px;
  border-radius: 14px 14px 0 0;

  img {
    width: 20px;
  }
}

a[href*='/notifications'] {
  .unreadNotifications {
    content: '';
    background-color: $vb-red;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: -8px;
    right: 8px;
  }
}
