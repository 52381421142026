@import '../../style/variables.scss';

$vb-checkbox-size: 20px;

.checkbox {
  width: $vb-checkbox-size;
  height: $vb-checkbox-size;
  min-width: $vb-checkbox-size;
  min-height: $vb-checkbox-size;
  border-radius: 4px;
  border: solid $vb-blue 2px;

  &.disabled {
    border-color: $mid-gray;
  }

  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .boxMark {
    width: 10px;
    height: 8px;
    visibility: hidden;
  }

  &.selected {
    background-color: $vb-blue;

    &.disabled {
      background-color: $mid-gray;
    }

    .boxMark {
      visibility: visible;
    }
  }
}

.label {
  margin-left: 5px;
}
