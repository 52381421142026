@import '../../style/variables.scss';

.card {
  // This is for when someone hovers over and it fades the image and says "Click for fullscreen"
  .fullscreenOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 13px;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    &:hover {
      opacity: 100;
    }
    .fullscreenText {
      font-size: 1.5rem;
      color: white;
    }
  }
  .imageContainer {
    position: relative;
    .imageOverlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      pointer-events: none;
      & > * {
        pointer-events: all;
      }
    }
    .imageWrap .img {
      width: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }

  .skeleton {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: block;
    line-height: 1;
  }

  &.cardBordered {
    box-shadow: 0px 0px 16px 5px rgba(#000, 0.1);
    .buttonContainer .buttonRow {
      padding-right: 5px;
    }
  }

  &.noTopPadding {
    padding-top: unset;
  }

  &.noBottomPadding {
    padding-bottom: unset;
  }
}

.cardRounded {
  border-radius: 19px;
}

.imgContainer {
  margin-bottom: 5px;
  overflow: hidden;

  &.skeletonContainer {
    position: relative;
    height: 0;
    width: 100%;
  }

  &.noMargin {
    margin-bottom: 0;
  }

  &.border {
    border: 2px solid $vb-blue;
  }

  &.borderRadius {
    border-radius: 13px;
  }
}

.cardPadded {
  padding: 8px;
  padding-bottom: 2rem;

  &.noBottomPadding {
    padding-bottom: unset;
  }
}

.cardExtraPadded {
  padding: 14px;
  padding-bottom: 18px;
}

.cardExtraBottomPadded {
  padding-bottom: 2rem;
}

.card.horizontal {
  // Display all inner items in a row
  display: flex;
  align-items: center;
  .imageContainer {
    width: 100%;
  }
}

.ranking {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  background-color: VAR(--vb-color-grey-mid);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
  padding-top: 0.2rem;
  box-shadow: 0px 0px 8px 4px rgb(0 0 0 / 15%);
  border: 2px solid white;
}

.editButton {
  position: absolute;
  right: 0.5rem;
  bottom: 1rem;

  &.higher {
    bottom: 5rem;
    right: 1rem;
  }
}

.titleVideo {
  flex-grow: 1;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--vb-color-black);
  text-decoration: underline;
  font-weight: 500;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 2rem;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &.mobile {
    padding: 0 10px;
  }
}
