@import '../../../style/variables.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
  position: relative;
  min-height: 2.5rem;
  transition: transform;
  max-width: 100vw;

  &.noTabs {
    align-items: flex-start;
    &.mobile .tabsWrapper {
      justify-content: start;
      padding-left: 1rem;
    }
  }

  .tabsWrapper {
    position: relative;
    width: MIN(100%, $max-width);
    text-align: center;
    padding: 6px;
  }
  &.tablet {
    .tabsWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
    .scrollDivContainer {
      flex-grow: 1;
      min-width: 0;
      width: 100%;

      // Add padding with negative margin so that selected tab underline is not cut off
      &.transparencyGradient {
        -webkit-mask-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
        mask-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%);
      }
      .scrollDiv {
        position: relative;
        z-index: 0;
        padding-left: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow-x: auto;
        overflow-y: hidden;
        padding-bottom: 16px;

        &::before,
        &::after {
          content: '';
        }

        & > * {
          margin: 0;
        }
      }
    }
  }
  .filterContainerDesktop {
    position: absolute;
    left: 20px;
  }
}
