.section {
  scroll-margin-top: 60px;

  &.headerVisible {
    scroll-margin-top: 125px;
  }

  .titleContainer {
    &.desktop {
      text-align: center;
    }

    .title {
      padding: 10px 0;
      text-align: center;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
  }

  .showMore {
    text-align: right;
    margin-top: 1rem;
    padding: 0 12px;
  }
}
