.fullWidthButton {
  width: 100%;
  margin: 0.5rem 0;
}

.sections {
  padding: 0.5rem 0;
}

.subsections {
  padding: 0.5rem 0;
}

.extraFilterContent {
  padding-bottom: 0.6rem;
}

.filters {
  padding: 0.5rem 0;
}
