.triviaQuestion {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  line-height: 1.5;

  .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: unset;
    margin-top: 1rem;
    margin-bottom: 1rem;
    list-style: none;

    .option {
      &.correct {
        label::before {
          border: 2px solid var(--vb-color-blue);
          background-color: var(--vb-color-blue);
        }
      }

      &.wrong {
        label::before {
          border: 2px solid rgb(243, 65, 65);
          background-color: rgb(243, 65, 65);
        }
      }
    }
  }
}
