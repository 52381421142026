@import '../../style/variables.scss';

:global(.modal-dialog) {
  &.xs {
    max-width: 500px;
  }

  &.sm {
    max-width: 700px;
  }

  &.med {
    // Used in PopupMakePost
    max-width: 880px;
  }

  &.lg {
    // Used in PopupAuth
    &.innerXl {
      width: 90%;
      max-width: $max-width;
    }
  }

  &.xl {
    // Used by PopupPublishItinerary
    width: 90%;
    max-width: $max-width;
    &.innerSm {
      width: 100%;
    }
  }
}

// Using a double selector to override the bootstrap global styles.
.content.content {
  border: none;
  border-radius: 24px;
  &.fullHeight {
    height: 100%;
  }
}

.body {
  padding: 22px 10px;
  &.noPaddingMobile {
    padding: 22px 0px;
  }
  &.bodyMd {
    padding: 30px 40px !important;
  }
}

.cancel {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
}

.childContainer {
  width: 100%;
  height: 100%;
}
