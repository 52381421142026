/*
    Style for adding posts.
*/
@import '../../../assets/style/variables.scss';

.container {
  color: $vb-black;

  .headerTitle {
    font-weight: 600;
    font-size: 2.28rem;
    margin-bottom: 15px;
  }

  .section {
    margin-top: 14px;
    margin-bottom: 14px;

    .label {
      display: block;
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 5px;

      &:hover {
        cursor: default;
        color: initial;
      }
    }

    .searchWrapper {
      position: relative;
      z-index: 2;
    }

    .captionInput {
      width: 100%;
      border-radius: 12px;

      textarea {
        box-sizing: border-box;
        font-size: 1.2em;
      }
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    padding: 0px 10px;
    justify-content: center;

    .buttonContainer {
      padding: 0px 20px;
      text-align: center;
    }
  }

  .successText {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;

    h2 {
      font-size: 1.6rem;
    }

    p {
      font-size: 1.2rem;
      max-width: 150px;
      font-size: 1.2rem;
      max-width: 50%;
      margin: auto;
      line-height: 1.5rem;
    }
  }

  .errMsg {
    color: $vb-red;
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  &.gteMdBreakpoint {
    min-width: 600px;
    display: block;

    .bottom {
      width: 100%;
      display: flex;
      justify-content: center;

      .column {
        flex: 1;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .noMarginTop {
          margin-top: 0;
        }

        .photoUpload {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }

    .buttons {
      max-height: 40px;
    }
  }
}
