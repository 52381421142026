@import '../../../style/variables.scss';

.shareButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    font-size: 0.92rem;
  }

  .logoContainer {
    background: $vb-blue;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    * {
      max-width: 32px;
      max-height: 32px;
      width: auto;
      height: auto;
    }
  }
}
