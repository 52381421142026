@import '../../../../assets//style/variables.scss';

.author {
  box-shadow: unset;
}

.trigger {
  color: $vb-black;
  font-weight: 600;
}
