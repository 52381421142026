@import '../../style/variables.scss';

.metaContainer {
  width: 100%;
  padding: 0.4rem 0;
  // .category {
  //   font-size: 0.86rem;
  //   color: $mid-gray;
  // }
  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      flex-grow: 1;
      &.titleVideo {
        font-size: 1rem;
        color: $vb-blue;
        text-decoration: underline;
      }
      &.titleSmall {
        flex-grow: unset;
        font-weight: bold;
        font-size: 1rem;
        // color: $vb-blue;
        // text-decoration: underline;
        &.titleVideo {
          font-weight: 500;
          text-decoration: none;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          max-height: 2rem;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      &.titleBig {
        color: #25252b;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        text-decoration: none;
        display: inline;
        display: -webkit-inline-box;
        -webkit-line-clamp: 1;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  .buttonContainer .buttonRow {
    padding-right: 0;
    .button {
      text-align: right;
      direction: rtl;
    }
    .buttonCentered {
      text-align: center;
    }
  }
}
.actionButtons {
  right: 8px;
  margin-left: 1.4rem;
  & > div {
    margin-bottom: 0.5rem;
  }
  &.afterTitle {
    margin-left: 2.5rem;
    position: relative;
    align-items: flex-start;
  }

  position: absolute;

  &.overridePositioning {
    height: 0;

    &.small {
      top: -63px;

      & > div:first-child {
        padding: 0.5px;
        background-color: VAR(--vb-color-grey-mid);
        border-radius: 3px;
        margin-bottom: 1.5rem;

        img {
          filter: invert(1) !important;
        }

        span {
          color: #ffffff !important;
        }
      }
    }
  }
}

.spaceButtons {
  padding-right: 4px;
  & > div {
    padding: 0 0.7rem;
    &:first-child {
      margin-left: -0.7rem;
    }
    &:last-child {
      margin-right: -0.7rem;
    }
  }
}

.metaContainerPadded {
  flex-grow: 1;
  padding: 10px;
}

.lowRisk {
  margin-left: 0.5rem;
}

.ranking {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  flex-shrink: 0;
  background: #bcbcbc;
  border-radius: 50%;
  width: 2.43rem;
  height: 2.43rem;
  font-family: monospace;
  color: #fff;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
}

.topContainer {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
}

.noStyleHeader {
  font-weight: inherit;
  font-size: inherit;
  text-decoration: inherit;
  margin: inherit;
  display: inline-block;
  &.video {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 2rem;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: inherit;
  }
}

.innerContainer {
  display: flex;
  flex-grow: 1;
}

.metaContainer {
  .topContainer {
    .title {
      .smallDetailsStyle {
        font-weight: 700;
        font-size: 2rem;
        margin-top: 0;
        margin-bottom: 0.4rem;
        margin-right: 0.5rem;
        display: inline;
        &.block {
          font-size: 1.65rem;
        }
      }
    }
  }
}
