@import '../../style/variables.scss';

.container {
  &.x {
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 10px;
    }
  }

  &.y {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
  }

  &::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $vb-blue;
    border-radius: 5px;
  }
}
