.container {
  display: flex;
  .emojiContainer {
    position: relative;
    border-radius: 100%;
    // height: 100%;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    &.activeEmoji {
      border: 2px solid var(--vb-color-yellow);
      box-shadow: 0px 0px 15px var(--vb-color-yellow);
      margin: CALC(0.5rem - 2px);
    }
  }

  .emoji {
    height: 35px;
    cursor: pointer;
  }
}

.underline {
  margin: 0;
  position: absolute;
  border-top: none;
  border-bottom: 2px solid var(--vb-color-yellow);
}

.vStack {
  display: flex;
  flex-direction: column;
  align-items: center;
}
