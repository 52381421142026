@import '../../assets/style/variables.scss';

.container {
  font-family: $main-font;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.9rem;
  color: $vb-black;
  width: 100%;
  overflow-x: clip;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.spinnerContainer {
  text-align: center;
}

// Prevent redux toastr from blocking mobile footer
:global(.redux-toastr) {
  :global(.bottom-center) {
    margin-bottom: 75px;
  }
}

// Remove safari/IOS styling from elements with button role
:global(*[type='button'], *[role='button']) {
  appearance: none !important;
  -webkit-appearance: none !important;
}

:global(a:hover) {
  color: $vb-blue-highlight;
}

:root {
  scroll-behavior: auto;
}

:global(#root) {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
}
