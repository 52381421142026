.heading {
  font-weight: bold;
  margin: 1rem 0;
  &.lg {
    font-size: 3rem;
    line-height: 3rem;
    &.screenSm {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  &.md {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  &.sm {
    font-size: 1.71rem;
    &.screenMd,
    &.screenSm {
      font-size: 1.4rem;
    }
    line-height: normal;
    font-weight: 600;
  }
  &.xs {
    font-size: 1.29rem;
    line-height: normal;
    margin: 0 0 0.64rem 0;
  }
  &.black {
    color: var(--vb-color-black);
  }
  &.blue {
    color: var(--vb-color-blue);
  }
  &.white {
    color: white;
  }
  &.noMargin {
    margin: 0;
  }
  &.center {
    text-align: center;
  }
}
