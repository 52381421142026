.container {
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    position: sticky;
    width: 100%;

    padding: 0 0.5rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 16px 5px rgb(0 0 0 / 10%);

    &.scrollable {
      overflow-y: auto;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
