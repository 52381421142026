.overlay {
  width: 100%;
  display: flex;
  justify-content: space-between;

  > div {
    flex: 1 1 0;
  }

  .hiddenGem,
  .popularity,
  .distance {
    margin-top: auto;
    padding: 12px;
  }

  .hiddenGem {
    color: rgb(255, 255, 255);
    font-size: 1.43rem;
    font-weight: 600;
    text-shadow: rgb(0 0 0) 0px 0px 10px;
    display: flex;

    align-items: center;
    pointer-events: none;

    img {
      width: 2.14rem;
      height: 2.14rem;
      margin-right: 0.36rem;
    }
  }

  .distance,
  .popularity {
    color: #ffffff;

    .content {
      display: inline-block;
      background-color: VAR(--vb-color-grey-mid);
      border: 2px solid white;
      padding: 0.2rem 0.6rem;
      border-radius: 0.5rem;
    }
  }

  .popularity {
    text-align: right;
  }

  .pillContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .badgeLabel {
      // Badges that show "Reviewed" or "1 Post" on profile page
      margin: 0px 15px 15px 0px;
      padding: 14px 20px;
      border-radius: 50px;
      font-size: 0.9rem;
      width: 100px;

      .checkmark {
        margin-right: 8px;
      }
    }
  }
}

.videoContainer {
  height: MIN(340px, CALC(100vw * 0.56266666666));
  width: 100%;
}
