@import '../../../../style/variables.scss';

.hamburgerDropdown {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  text-align: center;

  // This allows it to have a smooth open/close transition
  height: auto;
  margin: auto;
  max-height: 0px;
  overflow: hidden;
  transition: opacity 0s, visibility 0s, max-height 0.4s ease;
  &.white a {
    color: white;
  }
  &.open {
    // Height of the screen minus roughly the footer.
    max-height: CALC(100vh - 65px);
    // Was getting a weird scrollbar flicker when the hamburger opened when using this.
    // overflow-y: auto;
  }

  & > a {
    padding: 0px 10px;
    white-space: nowrap;
    display: block;
    margin: 8px 0;

    // Text styling
    font-size: 1.6rem;
    color: #3b3b3b;
    outline: none;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      color: $vb-blue;
    }
  }

  * {
    &::after {
      // Remove the blue underline for selected items
      background-color: transparent;
    }
  }
}
// &.homepage .hamburgerDropdown > * {
//   color: white;
// }

.hamburgerDivider {
  border-top: 1px solid $mid-gray;
}
