.content {
  flex: 1 0 420px;
}

.spinnerContainer {
  height: 3.86rem;
}

.captchaContainer {
  margin: 1rem 0;
}
