@import '../../style//variables.scss';;

.container {
  &.shadow {
    box-shadow: rgb(50 50 50 / 20%) 0px 0px 8px;
  }

  position: absolute;
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: start;

  .item {
    margin: 0px 12px 4px 12px;
    padding: 8px 0px 12px 0px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid lightgrey;
    align-items: center;
    color: $vb-black;
    text-decoration: none;

    &:hover {
      color: $vb-blue;
      text-decoration: none;
    }

    &:last-child {
      border-bottom: 0;
    }

    .label {
      flex-grow: 1;
      margin: 0px 10px 0px 10px;
      font-size: 15px;
      font-weight: 600;
      overflow-wrap: anywhere
    }
  }
}

.loading {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.listEmpty {
  padding: 8px 20px 20px 20px;
  color: #e24380;
  font-size: 1.25rem;
}
