.sidebar {
  width: 100%;
  padding-top: 80%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  margin-bottom: 1rem;
  &.desktop {
    height: 500px;
    margin-left: 30px;
    padding-top: 0;
    &.benefits {
      flex: 0 0 421px;
    }
  }
  &.splash {
    background-size: cover;
  }
}
