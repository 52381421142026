/* --------------------------------- Colors --------------------------------- */
$vb-blue: VAR(--vb-color-blue);
$vb-blue-highlight: VAR(--vb-color-blue-highlight);
$vb-black: VAR(--vb-color-black);
$vb-green: VAR(--vb-color-green);
$vb-red: VAR(--vb-color-red);
$vb-red-highlight: VAR(--vb-color-red-highlight);
$vb-yellow: VAR(--vb-color-yellow);
$vb-yellow-highlight: VAR(--vb-color-yellow-highlight);
$dark-grey: VAR(--vb-color-grey-dark);
$mid-grey: VAR(--vb-color-grey-mid);
$light-grey: VAR(--vb-color-grey-light);
$dark-gray: $dark-grey;
$mid-gray: $mid-grey;
$light-gray: $light-grey;
$max-width: VAR(--vb-max-width);

/* ---------------------------------- Font ---------------------------------- */
$main-font: Open Sans;

/* ---------------------------------- Sizes ---------------------------------- */
$header-height: 79px;
$header-height-small: 55px;
$mobile-footer-height: 79px;
