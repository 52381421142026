@import '../../style/variables.scss';

.container {
  position: fixed;
  bottom: 25vh;
  right: 0;
  z-index: 1000;
  background-color: $vb-blue;
  border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.5rem;

  .icon {
    width: 2rem;
    height: 2rem;
    margin: 0.5rem 0.5rem 0.5rem 0.75rem;
  }

  &.mobile {
    transform: translateX(CALC(100% - 0.5rem - 2rem - 0.75rem));
    transition: transform 0.3s;

    &.open {
      transform: translateX(0px);
    }
  }

  .inner {
    border: none;
    color: white;
    background: none;
    padding-left: 0;
  }
}
