@import '../../../assets/style/variables.scss';

.separatorLine {
  height: 1px;
  margin-top: 0;
  margin-bottom: 1.5rem;
  background-color: $light-gray;
  opacity: 1;
}

.link {
  color: $vb-blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.siteFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footerInner {
  padding: 1.5rem 0;
}

.footerCopyright {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  .legalCopy {
    color: $mid-gray;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }
  .bullet {
    padding-left: 20px;
    padding-right: 20px;
    &.lteSmBreakpoint {
      padding-left: 0px;
      padding-right: 10px;
    }
  }
  a {
    white-space: nowrap;
  }
}

.social img {
  padding-left: 20px;
}

/* Tablet Styles */
.social.gteLgBreakpoint {
  padding-top: 20px;
}
