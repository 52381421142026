@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin background-blur($pixelBlur) {
  backdrop-filter: blur($pixelBlur);
  -webkit-backdrop-filter: blur($pixelBlur);
}
