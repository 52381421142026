@import '../../../assets/style/variables.scss';

.inlineLink {
  font-size: 1rem;
  color: $vb-blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.contentContainer {
  height: 80vh;
  display: grid;
  grid-auto-rows: 222fr auto 308fr;
  background-position: center;
  background-size: cover;
  margin-bottom: 2rem;

  .mainBackground {
    position: absolute;
    height: 80vh;
    width: 100%;
    z-index: -1;
    .backgroundImage {
      background-position: center;
      background-size: cover;
    }
    :global(.carousel-root) {
      height: 100%;
      :global(.carousel) {
        height: 100%;
      }
    }
  }

  .splash {
    padding-top: $header-height;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .zuzuBubbles {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.75rem;
    flex-wrap: wrap;
  }

  .content {
    margin-left: 1rem;
    margin-right: 1rem;

    .tagline {
      color: white;
      text-shadow: 0px 0px 10px #000000;
      font-weight: 600;
      padding-bottom: 1rem;
      font-size: 3rem;
      max-width: none;
      width: 100%;
      text-align: center;
    }

    .searchBar {
      z-index: 1;
      display: flex;
      justify-content: center;
    }
  }
}

// .contentContainer.tablet {
// }

.contentContainer.mobile {
  margin-bottom: 1.5rem;
  .splash .content .tagline {
    font-size: 2rem;
  }
}
