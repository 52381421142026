@import '../../../style/variables.scss';

.container {
  margin-top: -0.3rem;
  font-size: 0.86rem;
  color: $mid-gray;
  margin: -0.2rem 0;
  display: flex;
  align-items: center;
}
