.container {
  font-size: 1.07rem;

  .avatar {
    border: 1px solid white;
  }
  .beenThere {
    margin-left: 0.5rem;
  }
  .amount {
    font-weight: bold;
    margin-left: 0.5rem;
  }

  margin-bottom: 0.5rem;
}
