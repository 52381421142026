@use 'sass:list';

@import '../../style/variables.scss';

@mixin vb-button {
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  user-select: none;
  font-weight: 500;
  line-height: 0;
  outline: none;
  white-space: nowrap;
  transition: color 0.1s, background 0.1s, border 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* -------------------------------------------------------------------------- */
/*                                    Size                                    */
/* -------------------------------------------------------------------------- */
.tny {
  @include vb-button;
  height: 1.21rem;
  &.circle {
    width: 1.21rem;
  }
  border-radius: 0.57rem;
  padding: 0.14rem 0.57rem;
  font-size: 0.64rem;
}

.xs {
  @include vb-button;
  height: 1.6rem;
  &.circle {
    width: 1.6rem;
  }
  border-radius: 0.8rem;
  padding: 0.3rem 0.8rem;
  font-size: 0.79rem;
}

.sm {
  @include vb-button;
  height: 2.5rem;
  &.circle {
    width: 2.5rem;
  }
  border-radius: 1.21em;
  padding: 0.71em 1.5em;
  font-size: 1em;
}

.med {
  @include vb-button;
  height: 2.86rem;
  &.circle {
    width: 2.86rem;
  }
  border-radius: 1.43rem;
  padding: 0.86rem 1.71rem;
  font-size: 1rem;
}

.lg {
  @include vb-button;
  height: 3.57rem;
  &.circle {
    width: 3.57rem;
  }
  border-radius: 1.79rem;
  padding: 0.86rem 1.86rem;
  font-size: 1.14rem;
}

/* -------------------------------------------------------------------------- */
/*                                Color Styles                                */
/* -------------------------------------------------------------------------- */

/* --------------------------------- Styles --------------------------------- */
@mixin full-button($color, $color-highlight) {
  @include vb-button;
  background-color: #{$color};
  color: #ffffff;
  &:hover,
  &:focus {
    background-color: #{$color-highlight};
  }
  &[disabled] {
    background-color: #6c757d;
    cursor: default;
  }
  &.focus {
    &:focus,
    &:focus-visible {
      box-shadow: $vb-blue 0px 0px 8px;
    }
  }
}

@mixin empty-button($color, $color-highlight) {
  @include vb-button;
  background-color: #ffffff;
  color: $mid-grey;
}

@mixin border-button($color, $color-highlight) {
  @include vb-button;
  background-color: #ffffff;
  color: $color;
  border: 1px solid $color;
  &:hover,
  &:focus {
    border-color: $color-highlight;
    color: $color-highlight;
  }
  &.focus {
    &:focus,
    &:focus-visible {
      box-shadow: $vb-blue 0px 0px 8px;
    }
  }
}

/* ---------------------------- Helper Functions ---------------------------- */
@mixin make-button-style($button-selector, $color-name, $is-default) {
  @if $is-default {
    #{$button-selector} {
      @content;
    }
  }
  #{#{$button-selector}-#{$color-name}} {
    @content;
  }
}

@mixin make-button-types($colors, $is-default) {
  @include make-button-style('.full', list.nth($colors, 1), $is-default) {
    @include full-button(list.nth($colors, 2), list.nth($colors, 3));
  }
  @include make-button-style('.empty', list.nth($colors, 1), $is-default) {
    @include empty-button(list.nth($colors, 2), list.nth($colors, 3));
  }
  @include make-button-style('.border', list.nth($colors, 1), $is-default) {
    @include border-button(list.nth($colors, 2), list.nth($colors, 3));
  }
}

@mixin make-colored-buttons($colors...) {
  @for $i from 0 to length($colors) {
    @include make-button-types(nth($colors, $i + 1), false);
  }
}
/* ------------------------- Instantiate Styles ------------------------- */
// Define colors for the buttons.
$blue: 'blue', $vb-blue, $vb-blue-highlight;
$red: 'red', $vb-red, $vb-red-highlight;
$green: 'green', $vb-green, $vb-green-highlight;
$yellow: 'yellow', $vb-yellow, $vb-yellow-highlight;
// Set the default color.
@include make-button-types($blue, true);
// Set all other colors (including default, so it creates a specific version for it)
@include make-colored-buttons($blue, $red, $green, $yellow);

/* -------------------------------------------------------------------------- */
/*                              Badges and Other                              */
/* -------------------------------------------------------------------------- */

// This is basically the same as a button except it has no hover effects and if for display.
.badgeFull {
  @include vb-button;
  background-color: $vb-blue;
  color: #ffffff;
  padding: 8px 12px;
}

.cardBadge {
  @include vb-button;
  background-color: $vb-blue;
  color: #ffffff;
  width: 100px;
  padding: 0;
  font-size: 12px;
}

// This is basically the same as a button except it has no hover effects and if for display.
.collection {
  @include vb-button;
  background-color: $vb-yellow;
  color: #ffffff;
  padding: 8px 12px;
  border-radius: 12px;
  transition: background-color 0.2s;
  // cursor: pointer;
  &:visited,
  &:hover {
    color: white;
  }
  &:hover {
    text-decoration: none;
    background-color: $vb-yellow-highlight;
  }
}

.circle {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
