@import '../../style/variables.scss';

.cardContainer {
  width: 100%;
  position: relative;

  &.refreshing {
    opacity: 0.8;
  }
  &.selected {
    border-left: 4px solid $vb-blue;
    padding-left: 4px;
  }
  &.notSelected {
    padding-left: 8px;
  }
}

.lastWrapper {
  position: relative;
  min-height: 35rem;
  width: 100%;
}

.last {
  min-height: 35rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 20%;
  background: linear-gradient(to bottom, transparent, #ffffff 30%);

  .or {
    margin-bottom: 0.5rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.featuredContent {
  display: flex;
  flex-direction: row;

  .cardContainer {
    width: auto;
  }

  & > * {
    &:first-child {
      flex-basis: 60%;
    }
    &:last-child {
      flex-basis: 40%;
    }
  }
}

.noResults {
  margin: 1rem 0;

  .inner {
    text-align: center;
  }
}

.buttons {
  margin: 1rem auto;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & > button {
    display: inline-block;
  }
}

.switch {
  max-width: 800px;
}

.faqs {
  margin-top: 1.5rem;
  padding: 0 12px 1.5rem;

  .faqsTitle {
    margin-bottom: 1rem;
  }

  .faqsList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.aboutActionsContainer {
  margin-top: auto;

  .aboutActions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    margin: 1rem 0 0.5rem;
  }
}

.bottom {
  width: 100%;
  margin-bottom: 1rem;
}

.switchText {
  width: 100%;
  margin: 0.5rem 10px;
  font-size: 1.17rem;
  text-align: center;
}

.innerContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.noItems {
  width: 100%;
  text-align: center;
  font-size: 1.14rem;
}

.columnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0.4rem;
}

.desktop {
  .columnContainer {
    flex-direction: row;
    justify-content: center;
  }
  .content {
    flex-grow: 1;
  }
}

.fromBottom {
  display: flex;
  width: 80vw;
  justify-content: space-evenly;

  .random {
    width: 65%;
  }
}

.aboutMaxWidthContainer {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.signUp {
  font-size: 1.25rem;
}
