@import '../../style/variables.scss';

.ageContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 0.5rem;
    &.fullWidth {
      width: 100%;
    }
  }

  .ages {
    color: $dark-gray;
    font-weight: 600;
    font-size: 1.08rem;
  }
}

.ageContainer.vertical {
  flex-direction: column;

  & > * {
    margin: 0.25rem 0;
  }

  &.leftAlign {
    align-items: flex-start;
  }
}
