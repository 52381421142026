@import '../../../style/variables.scss';
@import '../../../style/util.scss';

.aboutContainer {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: $vb-blue;
  padding: 1.3rem;
  color: white;
  margin: 1rem 0;
  min-height: 150px;

  &.noTopMargin {
    margin-top: 8px;
  }

  &.noBottomMargin {
    margin-bottom: 8px;
  }

  p {
    font-size: 0.92rem;
    text-decoration: none;
  }

  .spinner {
    display: flex;
    align-items: center;
    height: 72px;
  }
}

.tabletStyle .aboutContainer {
  margin-bottom: 0;
}

.mobile .aboutContainer {
  padding: 0.5rem 0.9rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.mobile {
  .aboutContainer {
    padding: 0.5rem 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    .description {
      line-height: 1.3rem;
      margin: 0.2rem 0;
    }
  }
}

.topLink {
  flex-grow: 1;
}

.top {
  display: flex;
  flex-direction: row;
  align-items: bottom;

  .heading {
    font-size: 1.29rem !important;
    font-weight: 600 !important;
    margin: 0.5rem 0 0 0 !important;
    line-height: 1.5;
  }

  & > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.outer {
  display: flex;
  flex-direction: column;
}

.collections {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  & > * {
    display: block;
    width: 50%;
    overflow: hidden;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .collectionBase {
    height: 34px;
    border-radius: 12px;
    margin: 0 0.2rem;
    display: flex;

    &.collection {
      background-color: $vb-yellow;
      color: white;
      font-weight: 600;
      font-size: 16px;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0 1rem;
    }
  }

  .inner {
    @include line-clamp(1);
  }
}

// .buttonContainer {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
//   margin: 1rem 0;
//   margin-right: -1rem;

//   & > * {
//     margin-right: 1rem;
//   }
// }

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
  margin-bottom: 0.4rem;

  & > * {
    width: 100%;
    margin: 0.3rem 0;
  }
}

.mapContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    flex-grow: 1;
  }

  &.hideAbout {
    margin-top: 0.5rem;
  }
}

// .outer.tablet {
//   min-height: 220px;
// }

// .outer.mobile {
//   min-height: 140px;
// }

.buttons {
  display: flex;
  gap: 10px;
}
