.description {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;

  .content {
    a {
      color: white;
    }
  }

  .truncated {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .more {
    margin-top: 0.25rem;
    align-self: flex-end;
    color: white;
  }
}
