.dropdown {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-width: max-content;
}

.inputContainer {
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.paddedLabel {
  padding: 0 0.5rem;
}

.button {
  width: 100%;
  margin-bottom: 0.5rem;
}
