@import '../../../../../assets/style/variables.scss';

.container {
  max-width: MIN(100vw, 500px);
  min-width: 250px;
  padding: 0.5rem 1.5rem;

  .inputContainer {
    margin-bottom: 0.5rem;
  }

  .error {
    color: $vb-red;
    margin-bottom: 0.3rem;
  }

  .bottom {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
