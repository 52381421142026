.sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 3rem !important;

  &.tablet {
    padding-top: 1rem;
  }
}

.bottomButtons {
  margin-top: 1rem;
}