// All buttons
.option {
  flex-grow: 1;
}
// First button
.option:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
// Middle buttons
.option:not(:first-child):not(:last-child) {
  margin-left: -1px;
  border-radius: 0;
}
// Last button
.option:last-child {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
