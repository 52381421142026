.container {
  transform: translate(-59%, -59%);

  .likeButton {
    color: white;
    cursor: pointer;
    position: relative;
    left: 0.18rem;
  }

  .label {
    color: #aaabbb;
    font-size: 0.9rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
