.container {
  position: relative;
  overflow: hidden;

  .image {
    width: 100%;
    height: auto;

    &.small {
      filter: blur(20px);
    }

    &.full {
      position: absolute;
      inset: 0 0 0 0;
    }

    opacity: 0;
    transition: opacity 400ms ease 0ms;
    &.noTransition {
      transition: none;
    }

    &.visible {
      opacity: 1;
    }
  }
}
