.ratingSummary {
  display: flex;
  align-items: center;
  flex: unset !important;

  background-color: VAR(--vb-color-grey-mid);
  border: 2px solid white;
  padding: 0.25rem 0.6rem;
  border-radius: 0.5rem;

  gap: 15px;
  margin: 12px;

  .stars {
    display: flex;
    align-items: center;
  }

  .text {
    color: #ffffff;
  }
}
