@import '../../style/variables.scss';

.container {
  width: 100%;

  .list {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    &.double {
      max-width: 1300px;
    }
    .item {
      margin-bottom: 1.5rem;
    }
    .doubleCities {
      display: flex;
      flex-direction: row;
    }
  }

  .testimonialGrid {
    display: grid;
    grid-template-columns: 5fr 5fr;
    grid-template-rows: 1fr;
    gap: 1rem;
    margin: 0 3rem;
  }

  .bottom {
    margin-bottom: 2rem;

    .bottomButtons {
      display: flex;
      justify-content: center;

      & > * {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .top {
    width: 100%;
    text-align: center;
    color: $dark-gray !important;
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }

  &.mobile {
    .testimonialGrid {
      grid-template-columns: 1fr;
    }
    .top {
      font-size: 1.3rem;
    }
  }
}
