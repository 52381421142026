@import '../../style/variables.scss';

.filterContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  justify-self: center;
  width: 100%;

  &.vertical {
    flex-direction: column;
  }

  &.scroll {
    display: grid;
    grid-auto-columns: max-content;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    overflow-x: auto;
    column-gap: 1rem;
    justify-items: center;
    align-items: center;
    padding-bottom: 1rem;
    padding-left: 0.1rem;
    justify-content: unset;
  }

  .filterButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.vertical {
      width: 100%;
      align-items: flex-start;
    }

    .button.vertical {
      width: 100%;
    }

    .filterName {
      margin: 0;
      color: $dark-gray;

      &.vertical {
        font-size: 1rem;
        margin-left: 6px;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

.filterBottom {
  margin-top: 1rem;
}

.filterOptions {
  &.shown {
    max-height: 100vh;
    transition: max-height 0.5s ease;
  }

  &.hidden {
    transition: max-height 0.25s ease;
    max-height: 0vh;
    overflow: hidden;
  }

  &.vertical {
    margin: 0.5rem 0rem;
    width: 100%;

    &:last-child {
      margin: 0;
    }
  }
}
