.dotWrapper {
  width: 24px;
  display: inline-block;
  .dot {
    height: 4px;
    width: 4px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 0 0.8rem;
    margin-left: auto;
    margin-right: auto;
  }
}
