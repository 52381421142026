@import '../../style/variables.scss';

.input {
  height: 40px;
  border-radius: 20px;
  box-shadow: rgb(50 50 50 / 20%) 0px 0px 8px;
  resize: none;
  border: 0px;
  color: $vb-black;
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;

  & > div {
    flex-grow: 1;
  }

  &:focus,
  &:focus-visible {
    box-shadow: $vb-blue 0px 0px 8px;
    outline: none;
  }
  &.hasButton {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
.inputGroupText {
  border: 0px;
  padding: 0px;
  background-color: transparent;
  color: $vb-black;
  .button {
    position: relative;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    float: right;
  }
}

.label {
  font-size: 0.86rem;
  margin-left: 6px;
}

.inputContainer {
  position: relative;
}
