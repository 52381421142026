.spinnerContainer {
  height: calc(2.86rem + 20px);
}

.container {
  flex: 1 0 480px;
}

.notice {
  a[href*='/wp-login.php?action=lostpassword'] {
    display: none;
  }
}

.captchaContainer {
  .captchaText {
    margin-bottom: 1rem;
  }
}
