.author {
  min-width: 150px;
  max-width: 400px;
  width: 100%;
  font-size: 16px;
  position: relative;
  z-index: 1;

  .searchInput {
    -webkit-appearance: none;
    z-index: 1;
    position: relative;
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px;
    padding-left: 16px;
    padding-right: 85px;
    border: none;
    text-decoration: none;
    line-height: normal;
    font-size: 16px;
    flex: 3 0 25px;
    border-radius: 25px;
    border: 1px solid #aaabbb !important;
  }

  :global(.dropdown) {
    z-index: 0;
    top: -8px;
  }

  .dropdownList {
    z-index: -1 !important;
  }
}
