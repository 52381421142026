@import '../../../../../assets/style/variables.scss';

.answer {
  color: var(--vb-color-red);
  margin-top: 0.15rem;
  padding-left: 0.25rem;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
